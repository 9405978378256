







import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DarkModeButton extends Vue {
    get isDarkThemeActive(): boolean {
        return this.$vuetify.theme.dark;
    }

    set isDarkThemeActive(value: boolean) {
        localStorage.setItem('darkmode', value ? '1' : '0');
        this.$vuetify.theme.dark = value;
    }

    created(): void {
        const savedDarkmodeValue = localStorage.getItem('darkmode');
        if (!savedDarkmodeValue) return;

        this.$vuetify.theme.dark = !!parseInt(savedDarkmodeValue);
    }
}
